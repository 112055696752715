import moment from 'moment-timezone';

import ConstantsHelper from './ConstantsHelper';
import StringHelper from './StringHelper';
import AnI18NextLibHelper from './AnI18NextLibHelper';
import UtilityHelper from './UtilityHelper';

import { IDateTimeFormats, IDayToFlag, ITimeRange, ITimestampRange, IShortDaysLabel } from '../types';
import { WindowEventObject } from '../model/models';

export default class DateTimeHelper {
    private static getStartOfDay = (mIn: moment.Moment): moment.Moment =>
        mIn.clone().set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

    private static formatDateRangeHelper = (
        begIso: string,
        endIso: string,
        extended = false,
        utcDates = false
    ): any => {
        if (begIso?.length > 0 && endIso?.length > 0) {
            let beg: moment.Moment;
            let end: moment.Moment;

            if (utcDates) {
                beg = moment.utc(begIso).startOf('day');
                end = moment.utc(endIso).startOf('day');
            } else {
                beg = moment(begIso).utcOffset(0);
                end = moment(endIso).utcOffset(0);
            }

            let response: any = {
                begDate: beg.format('YYYY/MM/DD'),
                endDate: end.format('YYYY/MM/DD'),
                begYear: beg.format('YYYY'),
                endYear: end.format('YYYY'),
                begMonth: beg.format('MMM'),
                endMonth: end.format('MMM'),
                begDay: beg.format('D'),
                endDay: end.format('D'),
            };

            if (extended) {
                response = {
                    ...response,
                    begWkdy: moment.weekdaysShort(beg.weekday()),
                    endWkdy: moment.weekdaysShort(end.weekday()),
                    begTime: beg.format('h:mm A'),
                    endTime: end.format('h:mm A'),
                };
            }

            return {
                ...response,
                diffInDays: end
                    .set({ hours: 0, minutes: 0, seconds: 0, millisecond: 0 })
                    .diff(beg.set({ hours: 0, minutes: 0, seconds: 0, millisecond: 0 }), 'days'),
            };
        } else {
            return null;
        }
    };

    private static getBegIso = (endIso: string): string => moment(endIso).add(-6, 'days').toISOString();

    private static formatDateRange = (dateFlds: any, shortFormat: boolean): string => {
        const yearChanged = dateFlds.begYear !== dateFlds.endYear;
        const begYearSlug = yearChanged ? `, ${dateFlds.begYear}` : '';
        const endMonthSlug =
            begYearSlug.length === 0 && dateFlds.begMonth === dateFlds.endMonth ? '' : `${dateFlds.endMonth} `;
        const prefixBeg = `${dateFlds.begMonth} ${dateFlds.begDay}`;
        const prefixEnd = `${endMonthSlug}${dateFlds.endDay}`;
        const PrefixBegshortEnd = `${prefixBeg}${
            yearChanged ? '' : StringHelper.NumberEnglishOrdinalSuffix(dateFlds.begDay)
        }`;
        const prefixEndShortFrmat = `${prefixEnd}${
            yearChanged ? '' : StringHelper.NumberEnglishOrdinalSuffix(dateFlds.endDay)
        }`;
        return shortFormat
            ? `${prefixBeg}-${prefixEnd}`
            : `${PrefixBegshortEnd}${begYearSlug} – ${prefixEndShortFrmat}, ${dateFlds.endYear}`;
    };

    private static formatTimeDeltaGetTags = (
        anI18Nextlib: any,
        days: number,
        hours: number,
        minutes: number,
        seconds: number,
        skipSeconds: boolean
    ) => {
        const tagDays: string =
            days > 0 &&
            `${StringHelper.FormatNumber(days, 0)} ${AnI18NextLibHelper.Translate(anI18Nextlib, 'dateTime.day')}`;
        const tagHours: string =
            (hours > 0 || (hours === 0 && days > 0)) &&
            `${StringHelper.FormatNumber(hours, 0)} ${AnI18NextLibHelper.Translate(anI18Nextlib, 'dateTime.hr')}`;
        const tagMinutes: string =
            (minutes > 0 || (minutes === 0 && (hours > 0 || days > 0))) &&
            `${StringHelper.FormatNumber(minutes, 0)} ${AnI18NextLibHelper.Translate(anI18Nextlib, 'dateTime.min')}`;
        const tagSeconds: string =
            !skipSeconds &&
            seconds >= 0 &&
            `${StringHelper.FormatNumber(seconds, 0)} ${AnI18NextLibHelper.Translate(anI18Nextlib, 'dateTime.sec')}`;

        return { tagDays, tagHours, tagMinutes, tagSeconds };
    };

    public static FormatTimeDelta = (
        anI18Nextlib: any,
        totalSecondsRaw: number,
        secondsOnly = false,
        skipSeconds = false,
        ceilingSeconds = false
    ): string => {
        let totalSeconds = totalSecondsRaw;

        if (skipSeconds) {
            totalSeconds = Math.round(totalSecondsRaw / 60) * 60;

            if (ceilingSeconds && totalSeconds < totalSecondsRaw) {
                totalSeconds += 60;
            }
        }

        const days = secondsOnly ? 0 : Math.floor(totalSeconds / 86400);
        const hours = secondsOnly ? 0 : Math.floor((totalSeconds - days * 86400) / 3600);
        const minutes = secondsOnly ? 0 : Math.floor((totalSeconds - (days * 86400 + hours * 3600)) / 60);
        const seconds = totalSeconds - (days * 86400 + hours * 3600 + minutes * 60);
        const { tagDays, tagHours, tagMinutes, tagSeconds } = DateTimeHelper.formatTimeDeltaGetTags(
            anI18Nextlib,
            days,
            hours,
            minutes,
            seconds,
            skipSeconds
        );
        const flds: string[] = [];

        if (tagDays) {
            flds.push(tagDays);
        }
        if (tagHours) {
            flds.push(tagHours);
        }
        if (tagMinutes) {
            flds.push(tagMinutes);
        }
        if (tagSeconds) {
            flds.push(tagSeconds);
        }

        return flds.join(' ');
    };

    public static FormatDateGlucoseTrends = (ts: string, weeksOffset: number): string =>
        moment(new Date(ts)).utcOffset(0).add(weeksOffset, 'weeks').format('MMM D');

    public static FormatTimeStamp = (
        ts?: string,
        fullTs = true,
        dateOnly = false,
        shortDate = false,
        shortTime = false,
        dateFormat: IDateTimeFormats = { primary: 'MMM D, YYYY', secondary: 'MMMM D, YYYY' },
        timeFormat: IDateTimeFormats = { primary: 'h:mm A', secondary: 'hh:mm A' }
    ): string => {
        const dateFormatToUse = shortDate ? dateFormat.primary : dateFormat.secondary;
        const timeFormatToUse = shortTime ? timeFormat.primary : timeFormat.secondary;
        return moment(ts ? new Date(ts) : new Date())
            .utcOffset(0)
            .format(
                `${fullTs || dateOnly ? dateFormatToUse : ''}${fullTs ? ' ' : ''}${
                    fullTs || !dateOnly ? timeFormatToUse : ''
                }`
            );
    };

    public static FormatDateIso = (dateValue: string, useNow = false): string =>
        dateValue || useNow
            ? moment(dateValue ?? new Date())
                  .utcOffset(0)
                  .format()
            : '';

    public static FormatDateRange = (
        endIso: string,
        begIso: string = DateTimeHelper.getBegIso(endIso),
        shortFormat = false
    ): string => {
        const dateFlds = DateTimeHelper.formatDateRangeHelper(begIso, endIso);

        return dateFlds ? DateTimeHelper.formatDateRange(dateFlds, shortFormat) : '';
    };

    public static GetDateRangeUtc = (begDate: string, endDate: string): { beg: string; end: string } => {
        const beg = moment.utc(begDate).toISOString();
        const end = moment.utc(endDate).add(24, 'hours').add(-1, 'milliseconds').toISOString();

        return { beg, end };
    };

    public static GetDateRangeDatesOnly = (endIso: string): { beg: Date; end: Date } => {
        const dataRange = DateTimeHelper.formatDateRangeHelper(DateTimeHelper.getBegIso(endIso), endIso);

        return {
            beg: new Date(dataRange?.begDate),
            end: new Date(dataRange?.endDate),
        };
    };

    public static GetEnrolledDaysFromDate = (beg: string): number =>
        DateTimeHelper.formatDateRangeHelper(beg, DateTimeHelper.GetDateNearestPastSaturday(), true)?.diffInDays;

    public static GetEnrolledDateFromDays = (days: number): string =>
        moment(DateTimeHelper.GetDateNearestPastSaturday()).add(-days, 'days').toISOString();

    public static GetTimeRangeHhMmSsOnly = (now: moment.Moment, hhMmSs: string): moment.Moment => {
        const newMoment = moment(hhMmSs, ['h:m:s a', 'H:m:s']);

        return now
            .clone()
            .set({
                hours: newMoment.hours(),
                minutes: newMoment.minutes(),
                seconds: newMoment.seconds(),
                milliseconds: 0,
            })
            .utcOffset(0);
    };

    public static FormatDateTimeRange = (
        anI18Nextlib: any,
        begIso: string,
        endIso: string,
        showYear = true
    ): ITimeRange => {
        let date = '';
        let time = '';
        let timeAlt = '';
        const suffix = '';
        const dateFlds = DateTimeHelper.formatDateRangeHelper(begIso, endIso, true);

        if (dateFlds) {
            const yearLabel = `, ${dateFlds.begYear}`;
            date = `${dateFlds.begWkdy}, ${dateFlds.begMonth} ${dateFlds.begDay}${showYear ? yearLabel : ''}`;
            time = `${dateFlds.begTime} – ${dateFlds.endTime}`;
            timeAlt = time.replace('–', AnI18NextLibHelper.Translate(anI18Nextlib, 'dateTime.and'));
        }

        return { date, time, timeAlt, suffix };
    };

    public static FormatTimeForChart = (
        ts: string,
        hrOnly = false,
        hrMinOnly = false,
        topOfHrsOnly = false,
        okIfNull = false
    ): any => {
        let output = null;

        if (ts?.length) {
            let fmt: string;

            if (hrOnly) {
                fmt = 'hA';
            } else if (hrMinOnly) {
                fmt = 'h:mm A';
            }

            const tsM = moment(new Date(ts)).utcOffset(0);

            output = (topOfHrsOnly && tsM.minutes() === 0) || !topOfHrsOnly ? tsM.format(fmt) : null;
        } else if (okIfNull) {
            output = '';
        }

        return output;
    };

    public static FormatDateForChart = (tsIso: string): any => {
        let output = null;

        if (tsIso?.length) {
            const tsMoment: moment.Moment = moment(tsIso).utcOffset(0);

            output = `${moment.weekdaysShort(tsMoment.weekday())}, ${tsMoment.format('MMM D')}`;
        }

        return output;
    };

    public static GetDateNearestPastSaturday = (workingDateIso: string = null, daysOffset = 0): string => {
        let workingDate: moment.Moment = moment(workingDateIso ?? new Date())
            .utcOffset(0)
            .add(daysOffset, 'days');
        const today = workingDate.isoWeekday();
        const targetDayNumber = 6; // for Saturday

        if (today > targetDayNumber) {
            workingDate = workingDate.isoWeekday(targetDayNumber);
        } else {
            workingDate = workingDate.add(-1, 'weeks').isoWeekday(targetDayNumber);
        }

        return workingDate
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .add(1, 'days')
            .add(-1, 'milliseconds')
            .toISOString();
    };

    public static GetReadingsDateRange = (
        beg: string,
        end: string,
        isBolus: boolean,
        offsetPadding = 0
    ): {
        beg: string;
        end: string;
        endShort: string;
    } => {
        const begMoment = moment(new Date(beg));
        const begOffsetMins: number = isBolus
            ? ConstantsHelper.ReadingsWindowOffsetBegBolus
            : ConstantsHelper.ReadingsWindowOffsetBegTimeOfDay;
        const endOffsetMins: number = ConstantsHelper.ReadingsWindowOffsetEnd;
        const endOffsetShortMins: number = ConstantsHelper.ReadingsWindowOffsetEndBolusShort;

        return {
            beg: begMoment
                .clone()
                .add(-(begOffsetMins + offsetPadding), 'minutes')
                .toISOString(),
            end: moment(new Date(end))
                .add(endOffsetMins + offsetPadding, 'minutes')
                .toISOString(),
            endShort: begMoment.clone().add(-endOffsetShortMins, 'minutes').toISOString(),
        };
    };

    public static GetSecToExpire = (dateToCheckIso: string): { diff: number; msg: string } => {
        let diff = 0;
        let msg = '';

        if (`${dateToCheckIso}`.trim().length > 0) {
            const mExpires = moment(dateToCheckIso);
            const mNow = moment(new Date());

            diff = mExpires.diff(mNow, 'seconds');
            msg = `IsSignInValid ${mExpires.toISOString()} - ${mNow.toISOString()} = ${diff} sec (time left)`;
        }

        return {
            diff,
            msg,
        };
    };

    public static GetOktaExpirationIsoTimestamp = (oktaExpiresIn = 0): string => {
        const now = new Date();
        // "oktaExpiresIn" is in sec
        const deltaSec = oktaExpiresIn * 1000 - now.valueOf();

        return DateTimeHelper.GetIsoDate(now.toUTCString(), 0, deltaSec, true);
    };
    public static AddMins = (dateOld: string, offsetMins: number): string =>
        moment(dateOld).utcOffset(0).add(offsetMins, 'minutes').toISOString();

    public static AddSecs = (dateOld: string, offsetSecs: number): string =>
        moment(dateOld).utcOffset(0).add(offsetSecs, 'seconds').toISOString();

    public static GetIsoNow = () => moment(new Date()).utcOffset(0).toISOString();

    private static zapMilliseconds = (mOld: moment.Moment): string =>
        mOld.utcOffset(0).set({ milliseconds: 0 }).toJSON().replace('.000Z', 'Z');

    public static ZapMilliseconds = (dateOld: string): string =>
        DateTimeHelper.zapMilliseconds(moment(dateOld).set({ milliseconds: 0 }));

    public static GetIsoDate = (
        dateOld: string,
        offsetDays = 0,
        offsetMilliseconds = 0,
        allowFutureDates = false
    ): string => {
        const dateNew: moment.Moment = moment(dateOld)
            .utcOffset(0)
            .add(offsetDays, 'days')
            .add(offsetMilliseconds, 'milliseconds');

        return !allowFutureDates && dateNew > moment().utcOffset(0) ? undefined : dateNew.toISOString();
    };

    public static GetIsoWeekRange = (curr: string, offsetDays = 0): ITimestampRange => {
        const end: string = DateTimeHelper.GetIsoDate(curr, offsetDays);
        const beg: string = DateTimeHelper.GetIsoDate(curr, offsetDays - 7, 1);

        return {
            beg,
            end,
        } as ITimestampRange;
    };

    public static GetTimeStampInLocalFormat = (ts: string) => moment(ts).format('YYYY-MM-DDTHH:mm:ssZZ');

    private static getDayInitialKey = (day: string) => {
        enum DaysAbbreviatedLabels {
            Sunday = 'Su',
            Monday = 'Mo',
            Tuesday = 'Tu',
            Wednesday = 'We',
            Thursday = 'Th',
            Friday = 'Fr',
            Saturday = 'Sa',
        }

        const dayInitialKeys: IShortDaysLabel = {
            [DaysAbbreviatedLabels.Sunday]: 'days.sundayInitial',
            [DaysAbbreviatedLabels.Monday]: 'days.mondayInitial',
            [DaysAbbreviatedLabels.Tuesday]: 'days.tuesdayInitial',
            [DaysAbbreviatedLabels.Wednesday]: 'days.wednesdayInitial',
            [DaysAbbreviatedLabels.Thursday]: 'days.thursdayInitial',
            [DaysAbbreviatedLabels.Friday]: 'days.fridayInitial',
            [DaysAbbreviatedLabels.Saturday]: 'days.saturdayInitial',
        };

        return dayInitialKeys[day as keyof IShortDaysLabel] || 'notAvailable';
    };

    public static GetDayDataForTimeStamp = (workingDateIso: string, anI18Nextlib: any): IDayToFlag => {
        const workingDate: moment.Moment = moment(new Date(workingDateIso)).utcOffset(0);
        const localeData = workingDate.localeData();
        const dayData = localeData.weekdaysMin(workingDate);
        const translationDayLabel = DateTimeHelper.getDayInitialKey(dayData.slice(0, 3));
        const dayLetter = AnI18NextLibHelper.Translate(anI18Nextlib, translationDayLabel);

        return {
            dayLetter,
            dayName: dayData?.slice(0, 3),
            flagged: false,
            timestamp: workingDateIso,
        };
    };

    public static GetDayLetters = (anI18Nextlib: any): IDayToFlag[] => {
        const localeData = moment.localeData(AnI18NextLibHelper.Translate(anI18Nextlib, 'localeCode'));

        return localeData.weekdaysMin().map((e: string) => {
            const translationDayLabel = DateTimeHelper.getDayInitialKey(e.slice(0, 3));
            const dayLetter = AnI18NextLibHelper.Translate(anI18Nextlib, translationDayLabel);

            return {
                dayLetter,
                dayName: e.slice(0, 3),
                flagged: false,
            };
        });
    };

    public static Format24HrToAmPm = (militaryTime: string): string => {
        const flds = `${militaryTime ?? '0:00'}:`.split(':');
        let hr = Number(flds[0]);
        const min = flds[1].padStart(Math.max(0, 2 - flds[1].length), '0');
        const amPm = hr > 11 ? 'P' : 'A';

        if (hr > 12) {
            hr -= 12;
        } else if (hr === 0) {
            hr = 12;
        }

        return `${hr}:${min} ${amPm}M`;
    };

    public static GetTimeOfDayName = (
        anI18Nextlib: any,
        hrMmAp: string
    ): {
        i18nPrefix: string;
        timeOfDayBlock: string;
    } => {
        // 12a =>  0
        //  1a =>  1
        // 11a => 11
        // 12p =>  0
        //  1p =>  1
        // 11p => 11
        const hr = Number(hrMmAp?.split(':')[0]);

        // Per SRS https://insulet.codebeamer.com/issue/1726
        // Per https://insulet.codebeamer.com/issue/2905?orgDitchnetTabPaneId=task-details-attachments
        // Night: 12am-5:59am
        // Morning: 6am-11:59am
        // Afternoon: 12pm-5:59pm
        // Evening: 6pm-11:59pm

        if (hr >= 18) {
            return {
                i18nPrefix: AnI18NextLibHelper.Translate(anI18Nextlib, 'dateTime.evening'),
                timeOfDayBlock: '6 PM - 12 AM',
            };
        } else if (hr >= 12) {
            return {
                i18nPrefix: AnI18NextLibHelper.Translate(anI18Nextlib, 'dateTime.afternoon'),
                timeOfDayBlock: '12 PM - 6 PM',
            };
        } else if (hr >= 6) {
            return {
                i18nPrefix: AnI18NextLibHelper.Translate(anI18Nextlib, 'dateTime.morning'),
                timeOfDayBlock: '6 AM - 12 PM',
            };
        } else {
            return {
                i18nPrefix: AnI18NextLibHelper.Translate(anI18Nextlib, 'dateTime.night'),
                timeOfDayBlock: '12 AM - 6 AM',
            };
        }
    };

    public static GetDurationInSeconds = (begIso: string, endIso: string, dropSeconds = false): number => {
        if (begIso?.length > 0 && endIso?.length > 0) {
            const beg: moment.Moment = moment(begIso).utcOffset(0);
            const end: moment.Moment = moment(endIso).utcOffset(0);

            if (dropSeconds) {
                beg.set({ seconds: 0 });
                end.set({ seconds: 0 });
            }

            return end.diff(beg, 'seconds');
        } else {
            return 0;
        }
    };

    public static IsWithinSingleReading = (first: string, second: string): boolean => {
        const diffInSec = Math.abs(DateTimeHelper.GetDurationInSeconds(first, second));

        return diffInSec < 10;
    };

    public static GetTimeStampMidPoint = (ts: string[]): string => {
        const minTs = UtilityHelper.GetMinVal(ts);

        return moment(minTs)
            .add(Math.floor(DateTimeHelper.GetDurationInSeconds(minTs, UtilityHelper.GetMaxVal(ts)) / 2), 'seconds')
            .toJSON();
    };

    private static getNextWeekRangeDateSub = (
        isoDate: moment.Moment,
        targetDayNumber: number,
        hrDir: number
    ): moment.Moment | null => {
        let isoFinal = null;
        let isoCheck = isoDate.clone();

        for (let tries = 0; tries < 3 && !isoFinal; tries++) {
            if (isoCheck.isoWeekday() === targetDayNumber) {
                isoFinal = isoCheck;
            } else {
                isoCheck = isoCheck.add(1 * hrDir, 'hour');
            }
        }

        return isoFinal;
    };

    public static GetNextWeekRangeDate = (isoDateOrig: moment.Moment, isEnd: boolean): moment.Moment => {
        const targetDayNumber = isEnd
            ? 6 // for Saturday
            : 7; // for Sunday
        const isoDate = DateTimeHelper.getStartOfDay(isoDateOrig.clone());
        let isoFinal = DateTimeHelper.getNextWeekRangeDateSub(isoDate, targetDayNumber, -1);

        if (!isoFinal) {
            isoFinal = DateTimeHelper.getNextWeekRangeDateSub(isoDate, targetDayNumber, 1);
        }

        return isoFinal;
    };

    public static GetIsoDatePart = (mIn: moment.Moment, idx = 0): string => {
        const flds = mIn.toISOString().split('T');

        return flds.length > idx ? flds[idx] : '';
    };

    /**
     * Get all the weeks ranges between the patient joining date and most recent saturday
     *
     * @param {Number} utcOffset Patient timezone
     * @param {String} targetDate Target or joining date ISO string
     * @returns {ITimestampRange[]}
     */
    public static GetPastWeekRangesTillDate = (targetDate: string): ITimestampRange[] => {
        const range: ITimestampRange[] = [];

        if (targetDate?.length > 0) {
            const targetDateYyMmDd = DateTimeHelper.GetIsoDatePart(moment.utc(targetDate).startOf('day'));
            const nearestPastSat = DateTimeHelper.GetDateNearestPastSaturday();
            let mWeekEnd = moment.utc(nearestPastSat).startOf('day');
            let mWeekBeg = DateTimeHelper.GetNextWeekRangeDate(mWeekEnd.clone().add(-6, 'days'), false);

            while (targetDateYyMmDd <= DateTimeHelper.GetIsoDatePart(mWeekEnd)) {
                range.push({
                    beg: DateTimeHelper.GetIsoDatePart(mWeekBeg),
                    end: DateTimeHelper.GetIsoDatePart(mWeekEnd),
                });

                mWeekBeg = DateTimeHelper.GetNextWeekRangeDate(mWeekBeg.add(-7, 'days'), false);
                mWeekEnd = DateTimeHelper.GetNextWeekRangeDate(mWeekEnd.add(-7, 'days'), true);
            }
        }

        return range;
    };

    /**
     * Format the week range on all reports page's week cards
     *
     * @param {Number} utcOffset Patient timezone
     * @param {ITimestampRange} ts Timestamp range object
     * @returns {String}
     */
    public static FormatAllReportsWeekRange = (ts: ITimestampRange): string => {
        const dateFlds = DateTimeHelper.formatDateRangeHelper(ts?.beg, ts?.end, false, true);

        if (dateFlds) {
            return `${dateFlds.begMonth} ${dateFlds.begDay}${StringHelper.NumberEnglishOrdinalSuffix(
                dateFlds.begDay
            )} - ${dateFlds.endMonth} ${dateFlds.endDay}${StringHelper.NumberEnglishOrdinalSuffix(dateFlds.endDay)}`;
        }

        return '';
    };

    private static getDateYear = (date: string): string => (date?.length > 0 ? moment.utc(date).format('YYYY') : '');

    /**
     * Get year from an ISO date string
     *
     * @param {String} dateBeg ISO date string
     * @param {String} dateEnd ISO date string
     * @returns {String}
     */
    public static GetDateYear = (dateBeg: string, dateEnd: string): string => {
        const yrBeg = DateTimeHelper.getDateYear(dateBeg);
        const yrEnd = DateTimeHelper.getDateYear(dateEnd);
        const valYrBeg = `${yrBeg} - `;
        return `${yrBeg === yrEnd ? '' : valYrBeg}${yrEnd}`;
    };

    public static AddUtcMidnightSuffix = (tsIn: string, skipTime = false) =>
        `${moment(tsIn).toJSON().split('T')[0]}${skipTime ? '' : 'T00:00:00.000Z'}`;

    public static GetEventWindow = (event: WindowEventObject, isBolus: boolean): ITimestampRange => ({
        beg: DateTimeHelper.zapMilliseconds(
            moment(event.beg)
                .utcOffset(0)
                .add({ hours: isBolus ? -3 : -2 })
        ),
        end: DateTimeHelper.zapMilliseconds(moment(event.end).utcOffset(0).add({ hours: 1 })),
    });

    public static GenerateTimeRange = (
        begIso: string,
        endIso: string,
        offsetHrsPre: number,
        offsetHrsPost: number,
        intervalSec: number,
        isTopOfHour: boolean
    ): string[] => {
        const range: string[] = [];

        if (begIso?.length > 0 && endIso?.length > 0) {
            const beg: moment.Moment = moment(begIso)
                .utcOffset(0)
                .add({ hours: offsetHrsPre, seconds: isTopOfHour ? intervalSec : 0 });
            const end: moment.Moment = moment(endIso).utcOffset(0).add({ hours: offsetHrsPost, seconds: intervalSec });

            if (isTopOfHour) {
                beg.set({ minutes: 0, seconds: 0, milliseconds: 0 });
                end.set({ minutes: 0, seconds: 0, milliseconds: 0 });
            }

            if (intervalSec !== 0) {
                for (let time = beg.clone(); time.diff(end, 'seconds') <= 0; time.add(intervalSec, 'seconds')) {
                    range.push(DateTimeHelper.zapMilliseconds(time));
                }
            }
        }

        return range;
    };
}
