import { random } from 'lodash';

import ConstantsHelper from './ConstantsHelper';
import DateTimeHelper from './DateTimeHelper';
import UtilityHelper from './UtilityHelper';
import StringHelper from './StringHelper';

import { IContributingEvent, ITranslator } from '../types';
import { WindowEventObject } from '../model/windowEventObject';
import { Bolus, DataItemModesPodStatuses, Mode, Reading, Submode } from '../model/models';
import { IBolusBase, IIDataItemReadings, IInsight, IReading } from '../store/insights/insights.types';

export default class EventHelper {
    public static GetEgvLabel = (translate: ITranslator, value: number, shortMode: boolean) => {
        let output = '--';

        if (UtilityHelper.IsNumeric(value)) {
            if (value > ConstantsHelper.EgvHighThreshold) {
                output = translate('highUc');

                if (!shortMode) {
                    output = `${output} (>${StringHelper.FormatNumber(ConstantsHelper.EgvHighThreshold, 1)})`;
                }
            } else if (value < ConstantsHelper.EgvLowThreshold) {
                output = translate('lowUc');

                if (!shortMode) {
                    output = `${output} (<${StringHelper.FormatNumber(ConstantsHelper.EgvLowThreshold, 1)})`;
                }
            } else {
                output = StringHelper.FormatNumber(value, 1);
            }
        }

        return output;
    };

    public static GetUniqueReadings = (readings: IReading[]): IReading[] => {
        const readingsUnique: IReading[] = [];

        readings?.forEach((r) => {
            if (!readingsUnique.find((u) => u.time === r.time)) {
                readingsUnique.push(r);
            }
        });

        return readingsUnique;
    };

    private static orderEventReadingsBoluses = (
        event: IContributingEvent,
        dataItemReadings: IIDataItemReadings
    ): Bolus[] => {
        return dataItemReadings.fetchedBoluses
            ? UtilityHelper.ReadingsSortAndDedupe(true, dataItemReadings.boluses, 'bolusDateTime').filter(
                  (bolusEntry) =>
                      bolusEntry.bolusDateTime >= event.windowBeg && bolusEntry.bolusDateTime <= event.windowEnd
              )
            : [];
    };

    private static orderEventReadingsModes = (
        event: IContributingEvent,
        dataItemReadings: IIDataItemReadings
    ): Mode[] => {
        return dataItemReadings.fetchedModes
            ? UtilityHelper.ReadingsSortAndDedupe(true, dataItemReadings.modes, 'startDate', 'endDate')
                  .map((modeEntry) => ({
                      ...modeEntry,
                  }))
                  .filter(
                      (modeEntry) =>
                          (modeEntry.startDate >= event.windowBeg && modeEntry.startDate <= event.windowEnd) ||
                          (modeEntry.endDate >= event.windowBeg && modeEntry.endDate <= event.windowEnd)
                  )
            : [];
    };

    private static orderEventReadingsSubmodes = (
        event: IContributingEvent,
        dataItemReadings: IIDataItemReadings
    ): Submode[] => {
        return dataItemReadings.fetchedModes
            ? UtilityHelper.ReadingsSortAndDedupe(true, dataItemReadings.submodes, 'startDate', 'endDate')
                  .map((submodeEntry) => ({
                      ...submodeEntry,
                  }))
                  .filter(
                      (modeEntry) =>
                          (modeEntry.startDate >= event.windowBeg && modeEntry.startDate <= event.windowEnd) ||
                          (modeEntry.endDate >= event.windowBeg && modeEntry.endDate <= event.windowEnd)
                  )
            : [];
    };

    private static orderEventReadingsPodStatuses = (
        event: IContributingEvent,
        dataItemReadings: IIDataItemReadings
    ): DataItemModesPodStatuses[] => {
        return dataItemReadings.fetchedModes
            ? UtilityHelper.ReadingsSortAndDedupe(true, dataItemReadings.podStatuses, 'timestamp').filter(
                  (podEntry) => podEntry.timestamp >= event.windowBeg && podEntry.timestamp <= event.windowEnd
              )
            : [];
    };

    private static orderEventReadingsReadings = (
        event: IContributingEvent,
        dataItemReadings: IIDataItemReadings
    ): Reading[] => {
        return dataItemReadings.fetchedReadings
            ? UtilityHelper.ReadingsSortAndDedupe(true, dataItemReadings.readings, 'dateTime').filter(
                  (readingEntry) => readingEntry.dateTime >= event.windowBeg && readingEntry.dateTime <= event.windowEnd
              )
            : [];
    };

    private static orderEventReadingsCombosInit = (
        timeRange: string[],
        readings: Reading[],
        readingsCombo: IReading[]
    ) => {
        timeRange.forEach((timeEnd, idxEnd) => {
            if (idxEnd > 0) {
                const idx = idxEnd - 1;
                const timeBeg = timeRange[idx];
                const matchedReadings = readings.filter(
                    (reading) => reading.dateTime >= timeBeg && reading.dateTime < timeEnd
                );

                if (matchedReadings.length > 0) {
                    matchedReadings.forEach((readingEntry) =>
                        readingsCombo.push({
                            time: readingEntry.dateTime,
                            egv:
                                readingEntry.egv >= ConstantsHelper.ReadingsRangeEgvMin &&
                                readingEntry.egv <= ConstantsHelper.ReadingsRangeEgvMax &&
                                UtilityHelper.RoundNumber(readingEntry.egv, 2),
                            basal: 0.1, // Note:  Temporary to compare old bar chart with new bullet chart
                        })
                    );
                } else if (
                    readingsCombo.length === 0 ||
                    DateTimeHelper.GetDurationInSeconds(readingsCombo[readingsCombo.length - 1].time, timeBeg) > 30
                ) {
                    readingsCombo.push({ time: timeBeg });
                }
            }
        });
    };

    private static orderEventReadingsCombosBoluses = (boluses: Bolus[], readingsCombo: IReading[]) => {
        boluses.forEach((bolusEntry) => {
            const readingsComboMatched = EventHelper.readingsComboFind(readingsCombo, bolusEntry.bolusDateTime);
            let totalBolusEnhanced = bolusEntry.totalBolus;

            /* istanbul ignore next: if statement never reachable from test since orderEventReadingsBoluses fn is already filtering boluses */
            if (
                !UtilityHelper.IsNumeric(bolusEntry.totalBolus) &&
                (UtilityHelper.IsNumeric(bolusEntry.mealBolus) || UtilityHelper.IsNumeric(bolusEntry.correctionBolus))
            ) {
                totalBolusEnhanced = (bolusEntry.mealBolus ?? 0) + (bolusEntry.correctionBolus ?? 0);
            }

            if (readingsComboMatched.length) {
                const newBolusEntry: IBolusBase = {
                    bolusCorrection: UtilityHelper.RoundNumber(bolusEntry.correctionBolus, 1),
                    bolusMeal: UtilityHelper.RoundNumber(bolusEntry.mealBolus, 1),
                    bolusTime: bolusEntry.bolusDateTime,
                    bolusTotal: UtilityHelper.RoundNumber(totalBolusEnhanced, 1),
                    bolusType: bolusEntry.bolusType,
                    carbs: UtilityHelper.RoundNumber(bolusEntry.carbs, 1),
                };

                if (!readingsComboMatched[0].boluses) {
                    readingsComboMatched[0].boluses = [];
                }

                readingsComboMatched[0].boluses.push(newBolusEntry);
            }
        });
    };

    private static orderEventReadingsCombosModes = (
        event: IContributingEvent,
        modes: Mode[],
        readingsCombo: IReading[]
    ) => {
        modes.forEach((modeEntry) => {
            const modeBegAfterEvent = DateTimeHelper.GetDurationInSeconds(event.windowBeg, modeEntry.startDate) > 0;
            const modeEndBeforeEvent = DateTimeHelper.GetDurationInSeconds(modeEntry.endDate, event.windowEnd) > 0;
            const modeStartDate = modeBegAfterEvent ? modeEntry.startDate : event.windowBeg;
            const modeEndDate = modeEndBeforeEvent ? modeEntry.endDate : event.windowEnd;
            const readingsComboMatched = EventHelper.readingsComboFind(readingsCombo, modeStartDate, modeEndDate);

            if (modeEntry.startDate !== modeStartDate) {
                modeEntry.startDate = modeStartDate;
            }

            if (modeEntry.endDate !== modeEndDate) {
                modeEntry.endDate = modeEndDate;
            }

            readingsComboMatched.forEach((readingComboEntry) => (readingComboEntry.mode = modeEntry.value));
        });
    };

    private static orderEventReadingsCombosSubmodes = (
        event: IContributingEvent,
        submodes: Submode[],
        readingsCombo: IReading[]
    ) => {
        submodes.forEach((submodeEntry) => {
            const submodeBegAfterEvent =
                DateTimeHelper.GetDurationInSeconds(event.windowBeg, submodeEntry.startDate) > 0;
            const submodeEndBeforeEvent =
                DateTimeHelper.GetDurationInSeconds(submodeEntry.endDate, event.windowEnd) > 0;
            const submodeStartDate = submodeBegAfterEvent ? submodeEntry.startDate : event.windowBeg;
            const submodeEndDate = submodeEndBeforeEvent ? submodeEntry.endDate : event.windowEnd;
            const readingsComboMatched = EventHelper.readingsComboFind(readingsCombo, submodeStartDate, submodeEndDate);

            if (submodeEntry.startDate !== submodeStartDate) {
                submodeEntry.startDate = submodeStartDate;
            }

            if (submodeEntry.endDate !== submodeEndDate) {
                submodeEntry.endDate = submodeEndDate;
            }

            readingsComboMatched.forEach((readingComboEntry) => (readingComboEntry.submode = submodeEntry.value));
        });
    };

    private static orderEventReadingsCombosPodStatuses = (
        podStatuses: DataItemModesPodStatuses[],
        readingsCombo: IReading[]
    ) => {
        podStatuses.forEach((podEntry) => {
            const readingsComboMatched = EventHelper.readingsComboFind(readingsCombo, podEntry.timestamp);

            if (readingsComboMatched.length) {
                readingsComboMatched[0].pod = podEntry.status;
            }
        });
    };

    public static OrderEventReadings = (
        eventId: string,
        insight: IInsight,
        fillGaps: boolean,
        dataItemReadings: IIDataItemReadings
    ): IIDataItemReadings => {
        const event: IContributingEvent = insight.windowEgvEvent?.find(
            (eventContributing) => eventContributing.eventId === eventId
        );
        let processedData = dataItemReadings.processedData;

        if (
            event &&
            !processedData &&
            (dataItemReadings.fetchedBoluses || dataItemReadings.fetchedModes || dataItemReadings.fetchedReadings)
        ) {
            processedData =
                dataItemReadings.fetchedBoluses && dataItemReadings.fetchedModes && dataItemReadings.fetchedReadings;

            const timeRange = DateTimeHelper.GenerateTimeRange(event.windowBeg, event.windowEnd, 0, 0, 300, false);
            const timeRangeToh = DateTimeHelper.GenerateTimeRange(event.windowBeg, event.windowEnd, 0, 0, 3600, true);
            const boluses = EventHelper.orderEventReadingsBoluses(event, dataItemReadings);
            const modes = EventHelper.orderEventReadingsModes(event, dataItemReadings);
            const submodes = EventHelper.orderEventReadingsSubmodes(event, dataItemReadings);
            const podStatuses = EventHelper.orderEventReadingsPodStatuses(event, dataItemReadings);
            const readings = EventHelper.orderEventReadingsReadings(event, dataItemReadings);
            const readingsCombo: IReading[] = [];

            EventHelper.orderEventReadingsCombosInit(timeRange, readings, readingsCombo);
            EventHelper.orderEventReadingsCombosBoluses(boluses, readingsCombo);
            EventHelper.orderEventReadingsCombosModes(event, modes, readingsCombo);
            EventHelper.orderEventReadingsCombosSubmodes(event, submodes, readingsCombo);
            EventHelper.orderEventReadingsCombosPodStatuses(podStatuses, readingsCombo);

            dataItemReadings.readingsComboGaps = EventHelper.readingsInjectTopOfHour(readingsCombo, timeRangeToh);
            dataItemReadings.readingsComboNoGaps = [];

            dataItemReadings.readingsComboGaps.forEach((readingEntry, idx) => {
                dataItemReadings.readingsComboNoGaps.push({
                    ...readingEntry,
                    egv:
                        UtilityHelper.IsNull(readingEntry.egv) && idx > 0
                            ? dataItemReadings.readingsComboNoGaps[idx - 1].egv
                            : readingEntry.egv,
                });
            });

            // Fill start of window null egv readings
            for (
                let idx = dataItemReadings.readingsComboNoGaps.findIndex((readingEntry) => readingEntry.egv);
                idx > 0;
                idx--
            ) {
                dataItemReadings.readingsComboNoGaps[idx - 1].egv = dataItemReadings.readingsComboNoGaps[idx].egv;
            }

            dataItemReadings = {
                ...dataItemReadings,
                boluses,
                modes,
                podStatuses,
                readings,
                readingsCombo: fillGaps ? dataItemReadings.readingsComboNoGaps : dataItemReadings.readingsComboGaps,
            };
        }

        return { ...dataItemReadings, processedData };
    };

    private static readingsComboAddEmptyEntry = (
        readingsCombo: IReading[],
        timeBeg: string,
        secondsOffset?: number,
        template?: IReading
    ): IReading => {
        const readingsComboNewEntry: IReading = {
            egv: null,
            ...template,
            id: '-1',
            time: secondsOffset ? DateTimeHelper.AddSecs(timeBeg, secondsOffset) : timeBeg,
        };

        readingsCombo.push(readingsComboNewEntry);

        return readingsComboNewEntry;
    };

    private static readingsComboFind = (readingsCombo: IReading[], timeBeg: string, timeEnd?: string): IReading[] => {
        let readingsComboMatched: IReading[] = [];

        if (timeEnd) {
            readingsComboMatched = readingsCombo.filter((r) => r.time >= timeBeg && r.time <= timeEnd);
        } else {
            const matchIdxPost = readingsCombo.findIndex((r) => r.time >= timeBeg);

            if (matchIdxPost > -1) {
                const matchIdxPre = matchIdxPost - (matchIdxPost > 0 ? 1 : 0);
                const distancetoPre = Math.abs(
                    DateTimeHelper.GetDurationInSeconds(timeBeg, readingsCombo[matchIdxPre].time)
                );
                const distancetoPost = Math.abs(
                    DateTimeHelper.GetDurationInSeconds(timeBeg, readingsCombo[matchIdxPost].time)
                );
                const indexToUse = distancetoPre > distancetoPost ? matchIdxPost : matchIdxPre;

                if (indexToUse > -1) {
                    readingsComboMatched.push(readingsCombo[indexToUse]);
                }
            }
        }

        if (readingsComboMatched.length === 0) {
            readingsComboMatched = [EventHelper.readingsComboAddEmptyEntry(readingsCombo, timeBeg)];
        }

        return readingsComboMatched;
    };

    private static readingsInjectTopOfHour = (readingsCombo: IReading[], timeRangeToh: string[]): IReading[] => {
        const readingsEnhanced: IReading[] = [];
        const readingsSortedUnique = UtilityHelper.ReadingsSortAndDedupe(false, readingsCombo, 'time');
        let tohIdx = 0;
        let id = 0;

        readingsSortedUnique.forEach((readingEntry, idx) => {
            if (tohIdx < timeRangeToh.length) {
                if (readingEntry.time === timeRangeToh[tohIdx]) {
                    tohIdx++;
                } else if (
                    idx > 0 &&
                    readingEntry.time > timeRangeToh[tohIdx] &&
                    readingsEnhanced[readingsEnhanced.length - 1].time !== timeRangeToh[tohIdx]
                ) {
                    const readingLast = readingsEnhanced[readingsEnhanced.length - 1];

                    readingsEnhanced.push({
                        egv: null,
                        basal: null,
                        mode: null,
                        submode: null,
                        ...readingLast, // Keep this in this position to override filler only if it is non-null
                        boluses: null,
                        pod: null,
                        id: `${id}`,
                        time: timeRangeToh[tohIdx],
                    });

                    tohIdx++;
                    id++;
                }
            }

            readingsEnhanced.push({
                ...readingEntry,
                id: `${id}`,
                basal: 0.1, // Note:  Remove this once bar chart has been removed from this project
            });

            id++;
        });

        return readingsEnhanced;
    };

    private static orderEventsSort = (isHigh: boolean, c1: IContributingEvent, c2: IContributingEvent): number => {
        if (c1.durationFull > c2.durationFull) {
            return -1;
        }

        if (c1.durationFull < c2.durationFull) {
            return 1;
        }

        if (isHigh) {
            return c1.extremeBGValue > c2.extremeBGValue ? -1 : 1;
        } else {
            return c1.extremeBGValue < c2.extremeBGValue ? -1 : 1;
        }
    };

    public static GenTestEgvValue = (isHigh: boolean, egvValue: number) => {
        if (isHigh && random() % 2) {
            return ConstantsHelper.EgvHighThreshold + random(1, 75);
        } else if (!isHigh && random() % 2) {
            return ConstantsHelper.EgvLowThreshold - random(1, 45);
        }

        return egvValue;
    };

    public static OrderEvents = (
        contributingEvents: WindowEventObject[],
        isBolus: boolean,
        isHigh: boolean
    ): IContributingEvent[] => {
        let contributingEventsEnhanced: IContributingEvent[] = contributingEvents?.map((c) => {
            const eventWindow = DateTimeHelper.GetEventWindow(c, isBolus);

            return {
                ...c,
                duration: DateTimeHelper.GetDurationInSeconds(c.beg, c.end, true),
                durationFull: DateTimeHelper.GetDurationInSeconds(c.beg, c.end),
                windowBeg: eventWindow.beg,
                windowEnd: eventWindow.end,
            };
        });

        if (isBolus) {
            // Bolus is sorted by descending duration and then
            // only top ConstantsHelper.DashboardMaxEventsPerBolusInsight events are used
            contributingEventsEnhanced = [...contributingEventsEnhanced]
                ?.sort((c1, c2) => EventHelper.orderEventsSort(isHigh, c1, c2))
                ?.filter((c, idx) => idx < ConstantsHelper.DashboardMaxEventsPerBolusInsight);
        }

        // All selected events are sorted by ascending beg time value
        return [...contributingEventsEnhanced]?.sort((c1, c2) => (c1.beg > c2.beg ? 1 : -1));
    };
}
