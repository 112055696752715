import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { detect } from 'detect-browser';
import { StatusCodes } from 'http-status-codes';
import { OktaAuth } from '@okta/okta-auth-js';

import { appInsights } from './appInsights';
import ConstantsHelper from './ConstantsHelper';
import StringHelper from './StringHelper';
import UtilityHelper from './UtilityHelper';
import AnI18NextLibHelper from './AnI18NextLibHelper';
import DateTimeHelper from './DateTimeHelper';
import UiHelper from './UiHelper';

import { IBrowserInfo, IBrowserType } from '../types';
import { setReloadInProgress } from '../store/app/app.slice';
import { IAuthenticationState, IOktaData } from '../store/authentication/authentication.types';

export default class SystemHelper {
    public static IsProd = (): boolean => `${SystemHelper?.GetRuntimeConfig('REACT_APP_IS_PROD')}`.trim() === '1';
    public static GetBrowserInfo = (): IBrowserInfo => {
        const browser = {
            name: '',
            os: '',
            version: '',
            ...detect(),
        };
        const browserName = `${browser.name}`.toLowerCase();
        const browserOs = `${browser.os}`.toLowerCase();
        const browserVersions = `${browser.version}.0.0`.split('.').map((versionFld) => Number(versionFld));
        const supportedBrowserData = SystemHelper?.GetRuntimeConfig('REACT_APP_BROWSER_SUPPORT') ?? '[]';
        const supportedBrowserJson: IBrowserType[] = JSON.parse(
            supportedBrowserData.length > 0 ? supportedBrowserData : '[]'
        ).map(
            (entry: IBrowserType) =>
                ({
                    ...entry,
                    name: entry.name.toLowerCase(),
                    osList: entry.osList.map((os: string) => os.toLowerCase()),
                } as IBrowserType)
        );
        const supported = !!supportedBrowserJson.find(
            (entry) =>
                (entry.name === browserName &&
                    entry.osList?.find((os) => os === browserOs) &&
                    browserVersions[0] > entry.major) ||
                (browserVersions[0] === entry.major && browserVersions[1] >= entry.minor)
        );

        return {
            ...browser,
            supported,
            tag: `${browser?.name}`,
        };
    };
    public static GetRuntimeConfig = (key: string, defVal: string = null): string => {
        let envValue: string = process.env[key];

        if (UtilityHelper.IsNull(envValue)) {
            const windowRunConfig = window['runConfig'];

            if (windowRunConfig) {
                envValue = windowRunConfig[key];
            }

            if (UtilityHelper.IsNull(envValue)) {
                envValue = defVal;
            }
        }

        return envValue;
    };
    /**
     * Returns current configured value
     * Priority configured in url < configured in settings(localstorage) < configured in runtime env
     * @param urlKey {String} - key of variable in url
     * @param settingsKey {String} - key of variable in settings
     * @param runtimeKey {String} - key of variable in runtime
     * @param allowConfigOverride {Boolean} - Optional param indicating if env entry can be overridden
     * @returns {String}
     */
    public static getConfig = (
        urlKey: string,
        settingsKey: string,
        runtimeKey: string,
        allowConfigOverride = false,
        saveToLocalStorage = false
    ): string => {
        let valueEffective: string = undefined;
        let valueLocalStorage: string = undefined;

        if (allowConfigOverride) {
            // Since env entry can be overridden, we check if the entry exists in url params
            const urlParams = new URLSearchParams(window?.location?.search);
            const valueUrlParams = urlParams.get(urlKey);

            // Fetch local storage value (if any) for fallback
            valueLocalStorage = UtilityHelper.SettingGet(settingsKey);

            // If url params is missing the use fallback
            if (StringHelper.IsEmptyString(valueUrlParams)) {
                valueEffective = valueLocalStorage;
            } else {
                valueEffective = valueUrlParams;
            }
        }

        if (UtilityHelper.IsNull(valueEffective)) {
            valueEffective = SystemHelper.GetRuntimeConfig(runtimeKey);
        }

        if (saveToLocalStorage && valueLocalStorage !== valueEffective) {
            UtilityHelper.SettingSet(settingsKey, valueEffective);
        }

        return valueEffective;
    };

    public static Fetch = async (
        anI18Nextlib: any,
        authenticationState: IAuthenticationState,
        url: string,
        optionsOverride: any = {},
        nonJsonResponse = false,
        allowNotFoundOrUnauthorizedOrBadRequest = false,
        textReponse = false
    ) => {
        const { timeout = 30000 } = optionsOverride;
        let status = StatusCodes.REQUEST_TIMEOUT;
        let msg = '';
        let data: any = {};
        let timedOut = false;
        let headers: Headers;

        const options = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${authenticationState?.oktaData?.access}`,
            },
            ...optionsOverride,
        };

        SystemHelper.AppInsightsEvent(anI18Nextlib, 'fetch()', `BE url:  ${options.method} ${url}`);

        try {
            const abortCtlr = new AbortController();
            const id = setTimeout(() => {
                timedOut = true;

                abortCtlr.abort();
            }, timeout);
            const response = await fetch(url, {
                ...options,
                signal: abortCtlr.signal,
            });

            clearTimeout(id);

            if (response.status) {
                status = response.status;
            }

            if (response.headers) {
                headers = response.headers;
            }

            if (
                response.status === StatusCodes.OK ||
                (response.status === StatusCodes.NO_CONTENT && options.method === 'POST') ||
                ((response.status === StatusCodes.NOT_FOUND ||
                    response.status === StatusCodes.UNAUTHORIZED ||
                    response.status === StatusCodes.BAD_REQUEST ||
                    response.status === StatusCodes.INTERNAL_SERVER_ERROR ||
                    response.status === StatusCodes.UNAVAILABLE_FOR_LEGAL_REASONS) &&
                    allowNotFoundOrUnauthorizedOrBadRequest)
            ) {
                msg = response.statusText;

                if (nonJsonResponse) {
                    data = response;
                } else if (textReponse) {
                    data = await response.text();
                } else {
                    data = await response.json();
                }
            } else {
                msg = response.statusText;
            }
        } catch (error) {
            SystemHelper.AppInsightsError(anI18Nextlib, 'Store', 'Fetch()', error);

            /* istanbul ignore next: else statment will never be reachable form tests */
            if (!timedOut) {
                status = StatusCodes.INTERNAL_SERVER_ERROR;
                msg = error;
            } else {
                msg = `Timed out after ${StringHelper.FormatNumber(timeout, 0)} ms`;
            }
        }

        return {
            status,
            msg,
            data,
            headers,
        };
    };

    public static DismissError = (dispatch: any) => {
        UiHelper.SetLoadingSemaphore(dispatch, 1);
        dispatch(setReloadInProgress({ reloadInProgress: true }));
        UiHelper.ClearErrors(dispatch);
        window.location.reload();
    };

    public static AppInsightsEvent = (anI18Nextlib: any, component: string, message: string) => {
        if (component && message) {
            appInsights.trackEvent({
                name: AnI18NextLibHelper.Translate(anI18Nextlib, ConstantsHelper.NameTag),
                properties: {
                    component,
                    message,
                },
            });

            if (!SystemHelper.IsProd()) {
                SystemHelper.AppInsightsInfo(anI18Nextlib, 'Dashboard', 'Debug', `[Event] ${component}:  ${message}`);
            }
        }
    };

    public static AppInsightsException = (
        anI18Nextlib: any,
        severityLevel: SeverityLevel,
        component: string,
        method: string,
        err: string
    ) => {
        if (component && method && err) {
            appInsights.trackException({
                exception: new Error(AnI18NextLibHelper.Translate(anI18Nextlib, ConstantsHelper.NameTag)),
                severityLevel,
                properties: {
                    component,
                    method,
                    err,
                },
            });
        }

        if (!SystemHelper.IsProd() && severityLevel !== SeverityLevel.Information) {
            SystemHelper.AppInsightsInfo(
                anI18Nextlib,
                'Dashboard',
                'Debug',
                `[Exception][${severityLevel.toString()}] ${component}:  ${method} ${err}`
            );
        }
    };

    public static AppInsightsError = (anI18Nextlib: any, component: string, method: string, err: string) =>
        SystemHelper.AppInsightsException(anI18Nextlib, SeverityLevel.Error, component, method, err);

    public static AppInsightsInfo = (anI18Nextlib: any, component: string, method: string, err: string) =>
        SystemHelper.AppInsightsException(anI18Nextlib, SeverityLevel.Information, component, method, err);

    public static AppInsightsWarn = (anI18Nextlib: any, component: string, method: string, err: string) =>
        SystemHelper.AppInsightsException(anI18Nextlib, SeverityLevel.Warning, component, method, err);

    public static ParseOktaData = (authState: any, pauseItEnabled: boolean): IOktaData => ({
        access: authState?.accessToken.accessToken,
        aud: authState?.accessToken?.claims?.aud,
        isUserDefined: pauseItEnabled
            ? authState?.accessToken?.claims?.uid != null
            : authState?.accessToken?.claims?.customerId != null,
        expires: DateTimeHelper.GetOktaExpirationIsoTimestamp(authState?.accessToken?.expiresAt),
        id: authState?.idToken?.idToken,
        userName: authState?.accessToken?.claims?.sub,
    });

    public static IsPauseItEnabled = (): boolean => {
        const pauseItFeatureToggle = SystemHelper.GetRuntimeConfig('REACT_APP_PAUSE_IT_FEATURE_TOGGLE') ?? false;
        return pauseItFeatureToggle.toString().toLowerCase() === true.toString();
    };

    public static IsHcpUserSession = (oktaAuth: OktaAuth, pauseItEnabled: boolean): boolean => {
        const tokenString = oktaAuth.getAccessToken();

        if (!tokenString) {
            return false;
        }

        const token = oktaAuth.token.decode(tokenString);

        if (pauseItEnabled) {
            return !!token.payload?.hcp;
        }

        // Note: for pauseIt off we need to check if token has customer id payload (customerId), if it does not then the user is HCP
        return token.payload ? !token.payload.customerId : false;
    };
}
